import React,{ useState,useEffect } from "react"
import Slider from "react-slick";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  arrows: false,
  // customPaging: function(i) {
  //   return (
  //     <span></span>
  //   );
  // },
  responsive: [
    {
      breakpoint: 980,
      settings: {
        variableWidth: false,
        centerMode: false
      }
    }
  ]
};

const AwardCats = ({ awardCat, awardCatTitle }) => {
const [showFilter,setShowFilter] = useState(false)
const breakpoints = useBreakpoint();


const updatePosts=(e)=>{
  if(awardCat[0].awards.nodes.length>3){
    setShowFilter(true)
  }else{
    setShowFilter(false)
  }
}
useEffect(() => {
  updatePosts()
}, []);

  return (
  <section className="content award-block">

   <div className="content-block clearfix">
      <div className="eighty-spacer"></div>
         <div className="centered-content-container">
            <div className="centered-title-holder">
              <span>{awardCatTitle ? awardCatTitle : 'Proud of our Achievements'}</span>
            </div>
         </div>

         {breakpoints.sm ?
           <div className="award-slick-container">
           <Slider className="slick-featured-card-container" {...slickSettings}>
      {awardCat[0].awards.nodes.length
        ? awardCat[0].awards.nodes.map((prod,index) => (
                <div className="slick-flex-mob specialist-car-finance" key={index}>
                     <div className="award-img-container">
                          <img src={`/htbcontent/uploads/${prod.featuredImage.mediaDetails.file}`} alt={prod.featuredImage.altText}  title={prod.featuredImage.title}></img>
                   </div>
                </div>
        ))
      : null}
      </Slider>
      </div>
           :
           <>
           {showFilter ?
           <div className="award-slick-container">
           <Slider className="slick-featured-card-container" {...slickSettings}>
      {awardCat[0].awards.nodes.length
        ? awardCat[0].awards.nodes.map((prod,index) => (
                <div className="slick-flex-mob specialist-car-finance" key={index}>
                     <div className="award-img-container">
                          <img src={`/htbcontent/uploads/${prod.featuredImage.mediaDetails.file}`} alt={prod.featuredImage.altText}  title={prod.featuredImage.title}></img>
                   </div>
                </div>
        ))
      : null}
      </Slider>
      </div>
      :
      <div className="award-slick-container">
      <div className="slick-list draggable">
      <div className="slick-track" style={{opacity:"1",width:"960px"}}>
  {awardCat[0].awards.nodes.length
   ? awardCat[0].awards.nodes.map((prod,index) => (
           <div className="slick-flex-mob specialist-car-finance" key={index}>
                <div className="award-img-container">
                     <img src={`/htbcontent/uploads/${prod.featuredImage.mediaDetails.file}`} alt={prod.featuredImage.altText}  title={prod.featuredImage.title}></img>
              </div>
           </div>
   ))
  : null}
  </div>
  </div>
  </div>
}</>
         }

    </div>
    <div className="eighty-spacer"></div>
    </section>
  )
}

export default AwardCats
